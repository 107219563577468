import { Slider, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation } from 'react-router-dom';
function priceText(value) {
    return `₹${value}`;
}

export const Filter = ({ openFilter, setOpenFilter, filterDataList, handleClear, price, setPrice, handleChangePriceRange, handleChangePriceRangeCommitted, handleChangeCategoryClick, handleChangeBrandClick, categoryfilter, brandfilter, categoryListData, priceRangeLoading }) => {

    const [expandedIndices, setExpandedIndices] = useState([]);
    const [textColor, setTextColor] = useState()
    const handleExpand = (index) => {
        const updatedIndices = [...expandedIndices];
        const indexPosition = updatedIndices.indexOf(index);
        if (indexPosition === -1) {
            updatedIndices.push(index);
        } else {
            updatedIndices.splice(indexPosition, 1);
        }
        setExpandedIndices(updatedIndices);
    };


    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    const subCategory = params.get('subCategory');
    useEffect(() => {

        if (category) {
            //setTextColor("#28a892")
            setTextColor("var(--secondary)")
        }
        if (subCategory) {
            //setTextColor("#28a892")
            setTextColor("var(--secondary)")
        }
    }, [location.search]);


    return (
        <>
            <div className={`filter ${!openFilter ? '' : 'show'}`}>
                <div className="card border-0 bg-white">
                    <div className="d-flex border-bottom justify-content-between"><h5 className='px-3 py-2  mb-0'>Filters</h5>
                        <h6 className='px-3 py-2  mb-0' style={{ cursor: 'pointer' }} onClick={() => handleClear()}>Clear All</h6></div>

                    <div className="filter_cat px-3 py-2 border-bottom">
                        <span style={{ marginBottom: "0px" }} className='filter_head' >Category</span>
                        {categoryListData?.map((item, index) => (
                            <div className='rolldown-list' style={{ padding: '5px 10px' }} key={index}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <div style={{ cursor: "pointer" }} onClick={() => handleExpand(index)}>
                                        {expandedIndices.includes(index) ? <RemoveIcon style={{ fontSize: '16px' }} /> : <AddIcon style={{ fontSize: '16px' }} />}
                                    </div>
                                    {/*<span onClick={() => handleSubCategoryClick(item?.categoryName)}>{item?.categoryName}</span>*/}
                                    <span className='cat_head' style={{ cursor: "pointer", color: `${category === item?.categoryName ? textColor : ''}` }} onClick={() => handleChangeCategoryClick(item)}>{item?.categoryName}</span>
                                </div>
                                {expandedIndices.includes(index) && (

                                    <div className='my-1' style={{ paddingLeft: "24px" }}>

                                        {item?.subCategoryList?.map((subItem, subIndex) => (
                                            <div key={subIndex}>
                                                <span className='cat_link' style={{ cursor: "pointer", color: `${subCategory === subItem.subCategoryName ? textColor : ''}` }} onClick={() => handleChangeCategoryClick(subItem)}>
                                                    {subItem.subCategoryName}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}

                    </div>
                    {
                        priceRangeLoading ?
                            <div className="filter_price px-3 py-2">
                                <span className='filter_head' >
                                    <Skeleton /></span>
                                <Skeleton />
                                <div className='d-flex align-items-center gap-1'>
                                    <div className="py-1 px-2 rounded">
                                        <Skeleton style={{ height: '50px', width: "105px" }} />
                                    </div>
                                    <div className="py-1 px-2 rounded">
                                        <Skeleton style={{ height: '50px', width: "105px" }} />
                                    </div>
                                </div>
                            </div>
                            : <div className="filter_price px-3 py-2">
                                <span className='filter_head' >Price</span>
                                <Slider
                                    getAriaLabel={() => 'Price range'}
                                    value={price}
                                    onChange={handleChangePriceRange}
                                    onChangeCommitted={handleChangePriceRangeCommitted}
                                    getAriaValueText={priceText}
                                    min={Number(filterDataList?.minPrice)}
                                    max={Number(filterDataList?.maxPrice)}
                                />
                                <div className='d-flex align-items-center gap-1'>
                                    <div className="bg-secondary py-1 px-2 rounded">
                                        <label htmlFor="" className='text-dark fw-bold'>Min</label>
                                        <input type="number"
                                            value={Number(price[0])}
                                            disabled
                                            onChange={(e) => {
                                                setPrice([Number(e.target.value), Number(price[1])]);
                                            }} />
                                    </div>
                                    <div className="bg-secondary py-1 px-2 rounded">
                                        <label htmlFor="" className='text-dark fw-bold'>Max</label>
                                        <input type="number" value={Number(price[1])}
                                            disabled
                                            onChange={(e) => {
                                                setPrice([Number(price[0]), Number(e.target.value)]);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                    }

                    {/*<div className="filter_cat px-3 py-2 border-bottom">*/}
                    {/*    <span className='filter_head' >Brands</span>*/}
                    {/*    {filterDataList !== {} && filterDataList?.brandList?.map((item, index) =>*/}
                    {/*        <div className="form-check" key={index}>*/}
                    {/*            <input className="form-check-input" type="checkbox" checked={brandfilter.includes(item.brandName)} id={(item.brandName).replace(' ', '')} onChange={(e) => { handleChangeBrandClick(e, item) }} />*/}
                    {/*            <label className="form-check-label" htmlFor={(item.brandName).replace(' ', '')}>{item.brandName}</label>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                </div>
            </div>
            {openFilter === true &&
                <div className={`over-lay`} onClick={() => {
                    setOpenFilter(false)
                }}></div>
            }
        </>
    )
}
