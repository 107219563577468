import { KeyboardBackspace } from '@mui/icons-material';
import { ButtonBase, Divider, Skeleton, Tooltip, Zoom } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import Loader from '../Common/Loader';
import { ApiCall } from '../Common/service';
import { Login } from '../Login/Login';
import { Address } from '../Profile/Address';



export const Checkout = () => {
    const [payment, setPayment] = useState('PAID');
    const [allCartData, setAllCartData] = useState({})
    const [selectedAddress, setSelectedAddress] = useState('')
    //const [loading, setLoading] = useState(true);
    const [addressList, setAddressList] = useState([]);
    const [deliveryInstructionInput, setDeliveryInstructionInput] = useState('')
    const [gstChekBox, setGstCheckBox] = useState(false)
    const [getSummarySetailsError, setgetSummarySetailsError] = useState(false)
    const [customerCartDetails, setCustomerCartDetails] = useState([])
    const { uniqueUserId, user, isAuthenticated } = useSelector((state) => state.authData)
    const [noteShow, setnoteShow] = useState(true)
    const [IsCODApplicable, setIsCODApplicable]=useState(false)
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const isFirstRender = useRef(true);
    const TokenData = { Token: user.token, LoginUserCode: user.loginUserCode, Source: 'web' }
    //for Gst In
    const [formGstData, setFormGstData] = useState({
        gstNumber: '',
        GstBusinessName: ''
    })
    const [rateData, setRateData] = useState('')
    const [makePaymentDataLoading, setMakePaymentDataLoading] = useState(false)
    const CartItemDetails = JSON.parse(localStorage.getItem('CartItemDetails'));

    const temp = {
        Token: user.token,
        LoginUserCode: user.loginUserCode,
        CartToken: uniqueUserId ? uniqueUserId : localStorage.getItem('uniqueUserId'),
        CartItemDetails: CartItemDetails,
        CheckOutToken: localStorage.getItem('CheckOutToken')
    } 

    function isValidGSTNo(gstNumber) {
        const str = gstNumber.toString()
        let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
        if (regex.test(str)) return true;
        else return false;
    }

    //Get Data by API
    const GetSummaryData = async () => {
        setMakePaymentDataLoading(true)
        let data = await ApiCall(`/api/Web/GetSummaryDetail`, { ...temp })
        if (data.status === 'SUCCESS') {
            setAllCartData(data)
            setCustomerCartDetails(data.cartList)
            const hasNonFreeShipping = data.cartList?.some(item => !item.isFreeShipping);
            
            setIsCODApplicable(data.cartList?.some(item => item.cod === true))
            console.log(IsCODApplicable,"IsCODApplicableIsCODApplicableIsCODApplicable")
            console.log(data.cartList?.some(item => !item.cod), "IsCODApplicableIsCODApplicableIsCODApplicable")
            if (hasNonFreeShipping) {
                handleRateCalculate()
            }
           // console.log(data.cartList,"data.cartList.isFreeShippingdata.cartList.isFreeShipping")
           // handleRateCalculate()
            setMakePaymentDataLoading(false)
        } else if (data.status === "ERROR") {
            setgetSummarySetailsError(true)
            setMakePaymentDataLoading(false);
            toast.error(data.message)
            navigate('/cart')   
        }
    }

    const GetAddresslists = async (token) => {
        const data = await ApiCall(`/api/Web/GetCustomerAddress`, { ...token })
        if (data.status === 'SUCCESS') {
            setAddressList(data.data) 
            const defaultAddresses = data?.data.filter(address => address.isDefaultAddress);

            // Print customerAddressID of default addresses
            defaultAddresses.forEach(address => {  
                setSelectedAddress(address.customerAddressID)
            });
        } else if (data.status === 'ERROR') {
            toast.error(data.message)
        }
    };

    const handleRateCalculate = async () => {
        if (selectedAddress === undefined || selectedAddress === '') {
            return;
        }
        
        const data1 = {
            Token: user.token,
            LoginUserCode: user.loginUserCode,
            Source: 'web',
            CustomerAddressID: selectedAddress,
            PaymentType: payment,
        }
        let data = await ApiCall(`/api/Web/RateCalculator`, { ...data1 })

        if (data.status === 'SUCCESS') {
            setRateData(data.chargesBreakup?.total_charge);
        } else if (data.status === "EXPIRED") {
            navigate('/Login')
        } else if (data.status === "ERROR") {
            toast.error(data.message)
        } 
    };

    const HandleOrderPlaced = async (CheckOutToken) => {
        
        if (selectedAddress === undefined) {
            toast.error("Please Select Deleviry Address")
            return;
        }
        setMakePaymentDataLoading(true)
        try {
            const orderData = {
                CustomerAddressID: selectedAddress,
                ShippingCharge: String(rateData),
                PaymentType: payment,
                DeliveryInstruction: deliveryInstructionInput ? deliveryInstructionInput : '',
                GstNumber: formGstData.gstNumber || '',
                GstBusinessName: formGstData.GstBusinessName || '',
                CheckOutToken: CheckOutToken,
                Token: user.token,
                IsGSTCheck: gstChekBox,
                LoginUserCode: user.loginUserCode,
                CartToken: uniqueUserId ? uniqueUserId : localStorage.getItem('uniqueUserId'),
                CartItemDetails: customerCartDetails?.map(product => ({
                     productCode: product.productCode,
                     Quantity: product.quantity,
                     requiredOtherProducts: product?.requiredOtherProducts?.map(item => item.productCode) || [],
                }))

            };
            let data = await ApiCall(`/api/Web/PlaceOrder`, { ...orderData });
            if (data.status === 'SUCCESS' && data.razorpayId) {
                let options = {
                    key: process.env.RAZORPAY_KEY,
                    amount: data.totalAmount,
                    currency: "INR",
                    name: "Indocraft World",
                    description: "Test Transaction",
                    image: "",
                    order_id: data.razorpayId,
                    handler: async function (response) {
                        let payloadData = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            Token: user.token,
                            LoginUserCode: user.loginUserCode,
                            CartToken: uniqueUserId ? uniqueUserId : localStorage.getItem('uniqueUserId'),
                            CheckOutToken: CheckOutToken,
                        }
                        let verifyPayment = await ApiCall(`/api/Web/VerifyPayment`, payloadData)
                        if (verifyPayment.status === "SUCCESS") {
                            dispatch(GetOverAllCountAPI())
                            //setMakePaymentDataLoading(false)
                            localStorage.removeItem("CheckOutToken")
                            await navigate(`/orderplaced?orderNo=${verifyPayment.orderNo}`);
                        } else if (verifyPayment.status === "Failure") {
                            setMakePaymentDataLoading(false)
                            toast.error('payment is not legit.')
                            navigate('/cart')
                        }
                    },
                    prefill: {
                        name: "",
                        email: "",
                        contact: "",
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };
                const razorPay = new window.Razorpay(options);
                razorPay.on("payment.failed", function (response) {
                    toast.error("Some error occured, Please try after sometime")
                    //alert(response.error.code);
                    //alert(response.error.description);
                    //alert(response.error.source);
                    //alert(response.error.step);
                    //alert(response.error.reason);
                    //alert(response.error.metadata.order_id);
                    //alert(response.error.metadata.payment_id);
                });
                razorPay.open();
            } else if (data.status === 'SUCCESS') {
                setMakePaymentDataLoading(false)
                dispatch(GetOverAllCountAPI())
                localStorage.removeItem("CheckOutToken")
                await navigate(`/orderplaced?orderNo=${data.orderNo}`);

            } else if (data.status === 'ERROR') {
                setMakePaymentDataLoading(false)
                toast.error(data.message);
               // navigate('/cart')
            }
            setMakePaymentDataLoading(false)
            
        } catch (error) {
            toast.error("An error occurred while placing the order. Please try again.");
            setMakePaymentDataLoading(false)
        }
    };

    function formatDateToCustomString(date) {
        const padToTwoDigits = (num) => num.toString().padStart(2, '0');
        const day = padToTwoDigits(date.getDate());
        const month = padToTwoDigits(date.getMonth() + 1);
        const year = date.getFullYear();
        const hours = padToTwoDigits(date.getHours());
        const minutes = padToTwoDigits(date.getMinutes());
        const seconds = padToTwoDigits(date.getSeconds());

        return `${day}${month}${year}${hours}${minutes}${seconds}`;
    }


    const genrateCheckOutToken = () => {
        const newUserId = uuidv4();
        const now = new Date();
        const formattedDate = formatDateToCustomString(now);
        const CombineDateAndToken = formattedDate + `-` + newUserId
        const fourtyLength = CombineDateAndToken.substring(0, 40)
        return fourtyLength;
    };
    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (gstChekBox) {
            if (!isValidGSTNo(formGstData.gstNumber)) {
                toast.error("Please Enter Valid GST Number");
                return;
            }
            if (formGstData.GstBusinessName === '') {
                toast.error("Please Enter GST Business Name");
                return;
            }
        } else {
            const checkOutToken = genrateCheckOutToken()
             HandleOrderPlaced(checkOutToken) 
        }
    };
    const totalAmount = Number(allCartData?.totalPayableAmount) + Number(rateData);
    useEffect(() => { 
            GetSummaryData();
    }, [selectedAddress])

    useEffect(() => {
        if (!isFirstRender.current) {
           // handleRateCalculate();
            GetSummaryData();
        }
    }, [payment])
    useEffect(() => {
        GetAddresslists(TokenData);
        if (selectedAddress) {
             GetSummaryData();
        }
        isFirstRender.current = false
    }, []);

    if (!isAuthenticated) {
        navigate('/login')
        return
    }

    if (getSummarySetailsError) {
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: '150px 0px' }}>
                    <h2>Oops Some Error Occured!</h2>
                    <div className="buttons-group text-center mt-4">
                        <Link to='/cart' className='bg-primary text-white py-3 px-4 border-0 rounded mybtn'><KeyboardBackspace />  Back to Cart</Link>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {isAuthenticated ? <>
                <section className="checkout py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="cart_card py-0 mt-3">
                                    {
                                        customerCartDetails?.map((cartDetails) => (
                                            <div className='border-bottom'>
                                                <div className="cart_product checkout_card_product  py-3">
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <figure className="figure m-0">
                                                            <img src={cartDetails?.productImageUrl} className="figure-img img-fluid" alt={cartDetails?.productName} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available_ads.png'; }} />

                                                            <img src="./images/lamp.jpg" className="figure-img img-fluid" alt="ProductImage_0" />
                                                        </figure>
                                                    </div>
                                                    <div className="product_info me-0">
                                                        <div>
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <figcaption className="figure-caption text-start mb-2">{cartDetails?.categoryName}</figcaption>
                                                            </div>
                                                            <Link to={`/${cartDetails?.searchEnginePageName}/${cartDetails?.productCode}`}>
                                                                <h6 className="productName w-100">{cartDetails?.productName}</h6>
                                                            </Link>
                                                            <span>QTY : {cartDetails?.quantity}</span>
                                                        </div>
                                                        <div className="text-md-end" style={{ cursor: 'default' }}>
                                                            <div className="my-3 my-md-0">
                                                                <div className="fw-bold fs-5">₹ {cartDetails?.sellingPrice}</div>
                                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                                    {
                                                                        cartDetails?.discount >= '1.00' &&
                                                                        <>
                                                                            M.R.P.: <span className="priceCompare text-decoration-line-through text-black-50 fw-medium fs-6">₹ {cartDetails?.mrp}</span>
                                                                            <span className='discount'>({cartDetails?.discountType === "FLAT" && "₹ "}{cartDetails?.discount}{cartDetails?.discountType === "PERCENTAGE" && "%"} Off)</span>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="cart_price">
                                                                Total  Amount / Qty.: &nbsp; <span>₹ {cartDetails?.totalAmount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {cartDetails?.requiredOtherProducts?.length > 0 &&
                                                        <div className="cart_product border-bottom required-products-container mt-2">
                                                            <div className="products-list d-flex">
                                                                {cartDetails?.requiredOtherProducts?.map((product, index) => (
                                                                    <div key={index} className="product-item d-flex" style={{ width: "-webkit-fill-available", gap: "10px", alignItems: "center" }}>
                                                                        <requiredFig className='requiredFig m-0'>
                                                                            <img src={product.productImageUrl} className="requiredFig-img img-fluid" alt={product.productName} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available_ads.png'; }} />
                                                                        </requiredFig>
                                                                        <div className="product_info">
                                                                            <div className="">
                                                                                <Tooltip arrow title={product.productName} TransitionComponent={Zoom} placement="top-start">

                                                                                    <Link to={`/${product.seoFriendly}/${product.productCode}`} className='productName ' style={{ fontSize: "13px" }}>{product.productName}</Link>
                                                                                </Tooltip>
                                                                                <p style={{ fontSize: "10px" }}>Brand: {product.brandName}</p>
                                                                                <p style={{ fontSize: "10px" }}>Price: ₹{product.sellingPrice}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                < >
                                    <div style={{ display: "none" }} className="exprs_chkot text-center">
                                        <span>Express Checkout</span>
                                        <div className="d-flex gap-3 my-3">
                                            <ButtonBase className='w-100'>
                                                <button className='w-100 py-2 text-white border-0 rounded' style={{ backgroundColor: '#5f209e' }}>
                                                    <img src="./images/PhonePe_Logo.png" width='80' alt="" />
                                                </button>
                                            </ButtonBase>
                                            <ButtonBase className='w-100'>
                                                <button className='bg-warning w-100 py-2 text-white border-0 rounded'>
                                                    <img src="./images/paypal.svg" width='75' alt="" />
                                                </button>
                                            </ButtonBase>
                                            <ButtonBase className='w-100'>
                                                <button className='bg-dark w-100 py-2 text-white border-0 rounded'>
                                                    <img src="./images/dark_gpay.svg" width='50' alt="" />
                                                </button>
                                            </ButtonBase>
                                        </div>
                                        <Divider>OR</Divider>
                                    </div>
                           
                                    <div className="cart_card mt-3">
                                        <Address
                                            tokenData={TokenData}
                                            addressList={addressList}
                                            setAddressList={setAddressList}
                                            setSelectedAddress={setSelectedAddress}
                                            selectedAddress={selectedAddress}
                                            noteShow={noteShow}
                                            setnoteShow={setnoteShow}
                                            heading='Select a delivery address'
                                        />
                                        <div className="tab_head pb-3 border-bottom">
                                            <h6 className='m-0'>Secure Payment</h6>
                                            <span>All transactions are secure</span>
                                        </div>
                                        <div className="select_pay_mode mt-3">
                                            <label className={`label_box mb-3 border-0`} htmlFor="cardmode">
                                                <input type="radio"
                                                    name='paymode'
                                                    id='cardmode'
                                                    value='creditCard'
                                                    onChange={(e) => setPayment(e.target.checked ? 'PAID' : "")}
                                                    checked={payment === "PAID" ? true : false}
                                                />
                                                <div className="d-flex justify-content-between gap-2 w-100 adrss_body position-relative">
                                                    <div>
                                                        <div className="circle_radio">
                                                        </div>
                                                        <p className='m-0'>Online Transaction</p>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <img src="./images/payment-icons.png" width='220' alt="" />
                                                    </div>
                                                </div>
                                            </label>
                                                <label className='label_box border-0 mb-3' htmlFor="cod">
                                                    <input
                                                        type="radio"
                                                        name='paymode'
                                                        id='cod'
                                                        value='COD'
                                                        onChange={(e) => setPayment(e.target.checked ? 'COD' : "")}
                                                    checked={payment === "COD"}
                                                    disabled={!IsCODApplicable}
                                                />
                                                    <div className="d-flex justify-content-between gap-2 w-100 adrss_body position-relative">
                                                        <div>
                                                            <div className="circle_radio"></div>
                                                        <Tooltip
                                                            arrow
                                                            title={IsCODApplicable ? '' : 'COD Not Available'}
                                                            TransitionComponent={Zoom}
                                                            placement="top-start"
                                                        >
                                                            <p className="m-0">
                                                                {'Cash on Delivery'}
                                                            </p>
                                                        </Tooltip>

                                                        </div>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <img src="./images/cash.svg" width='25' alt="" />
                                                        </div>
                                                    </div>
                                                </label>
                                        </div>
                                        <div className='tab_head'>
                                            <h6 className={`label_box `} htmlFor="cardmode">
                                                Special Delivery Instructions
                                            </h6>
                                        </div>
                                        <textarea
                                            placeholder="Give Delivery Instructions"
                                            rows='5'
                                            className='col-12 p-2'
                                            value={deliveryInstructionInput}
                                            onChange={(e) => setDeliveryInstructionInput(e.target.value)} />

                                        {
                                            !allCartData.isGSTIncluded && <div className='d-flex gap-2 align-items-center mt-2 '>
                                                <input onClick={() => setGstCheckBox(!gstChekBox)} type='checkbox' checked={gstChekBox} id="modalCheckbox" />
                                                <div className='tab_head'>
                                                    <label htmlFor="modalCheckbox">
                                                        <h6 style={{ marginBottom: '0px', cursor: "pointer" }} className={`label_box`}>
                                                            Use GST Invoice
                                                        </h6>
                                                    </label>
                                                </div>
                                            </div>
                                        }

                                        {
                                            gstChekBox && <div style={{ transform: 'scaleY(1)', transition: 'transform 0.3s ease' }}>

                                                <div className='tab_head mt-4'>
                                                    <h6 className={`label_box `} htmlFor="gstNumber ">
                                                        GST Number <span style={{ color: 'red' }}>*</span>
                                                    </h6>
                                                    <input
                                                        id='gstNumber'
                                                        maxLength='50'
                                                        required
                                                        onChange={(e) => setFormGstData({ ...formGstData, gstNumber: e.target.value })}
                                                        style={{ border: '1px solid #28a892', outline: 'none', borderRadius: '5px' }}
                                                        className='col-12 p-2'
                                                    />


                                                </div>

                                                <div className='tab_head mt-2'>
                                                    <h6 className={`label_box `} htmlFor="businessName ">
                                                        Business Name <span style={{ color: 'red' }}>*</span>
                                                    </h6>
                                                    <input
                                                        id='businessName'
                                                        maxLength='50'
                                                        required
                                                        onChange={(e) => setFormGstData({ ...formGstData, GstBusinessName: e.target.value })}
                                                        style={{ border: '1px solid #28a892', outline: 'none', borderRadius: '5px' }}
                                                        className='col-12 p-2'
                                                    />

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            </div>
                            <div className="col-md-4">
                                {!makePaymentDataLoading && Object.keys(allCartData).length !== 0 ?
                                    <div className="cart_card summary">
                                        <div className="cart_head border-bottom pb-2">
                                            <h4 className='mb-1'>Summary</h4>
                                        </div>
                                        <div className="table-responsive" >
                                            <table className="table table-sm table-borderless" >
                                                <tbody>
                                                    <tr className="">
                                                        <td className='fs-6 fw-bold'>ITEMS TOTAL ({allCartData?.totalItem})</td>
                                                        <td className='fs-6 fw-bold'>₹ {allCartData?.totalSellingPrice}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td> Total MRP</td>
                                                        <td className='priceCompare text-decoration-line-through text-black-50 fw-medium fs-6 fw-bold'>₹ {allCartData.totalMRPAmount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-success">Savings</td>
                                                        <td className="text-success">₹ {allCartData?.totalSavingAmount}</td>
                                                    </tr>
                                                   
                                                    <tr className="">
                                                        <td>Shipping</td>
                                                        <td className="">{rateData ? `₹ ${rateData}` : "0.00"}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td>Tax</td>
                                                        <td>{allCartData?.taxAmount}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="fs-6 fw-bold">Total Payable Amount</td>
                                                        <td className="fs-6 fw-bold">₹ {totalAmount.toFixed(2)}</td>
                                                    </tr>
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            allCartData.totalPayableAmount === '0' || Object.keys(allCartData).length === 0 ?
                                                <ButtonBase sx={{ width: '100%' }}><button className='bg-primary w-100 text-white border-0 rounded addbtn disabledBTN'>{payment === "COD" ? "Place Order" : "Pay Now"}</button></ButtonBase>
                                                :
                                                <ButtonBase sx={{ width: '100%' }}><button onClick={(e) => handleButtonClick(e)} className='bg-primary w-100 text-white border-0 rounded addbtn mybtn'>{payment === "COD" ? "Place Order" : "Pay Now"}</button></ButtonBase>

                                        }

                                        <ButtonBase ><button onClick={async () => {
                                            await localStorage.removeItem('checkOutDetails');
                                            await navigate(-1);
                                        }} className='bg-body text-center border-0 mt-2 '><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to Cart</button></ButtonBase>

                                    </div> :

                                    <div className="cart_card summary">

                                        <div className="cart_head border-bottom pb-2">
                                            <h4 className='mb-1'><Skeleton /></h4>
                                        </div>
                                        <div className="table-responsive" >
                                            <table className="table table-sm table-borderless" >
                                                <tbody>
                                                    <tr className="">
                                                        <td className='fs-6 fw-bold'><Skeleton /></td>
                                                        <td className='fs-6 fw-bold'><Skeleton /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-danger"><Skeleton /></td>
                                                        <td className="text-danger"><Skeleton /></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td><Skeleton /></td>
                                                        <td className="text-danger"><Skeleton /></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td><Skeleton /></td>
                                                        <td><Skeleton /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Skeleton height='250' />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </> : <><Login /></>}


            {makePaymentDataLoading && <Loader />}
        </>
    )
}

